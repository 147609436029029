import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching2 from '../../components/images/imageCoaching2.js'
import ImageDevelopement2 from '../../components/images/imageDevelopement2.js'
import ImageEmploi from '../../components/images/imageEmploi.js'
import ImageLeft4 from '../../components/images/imageLeft4.js'

const Transition = () => (
  <Layout>
    <SEO title="Bilan et transition de carrière | Coaching | Gestion Mieux-Être" />
    <PageHeader
        header="Bilan et transition de carrière"
        text="Nous vous épaulons afin de faire progresser significativement vos opportunités de carrière.  Pour réaliser votre plein potentiel, nous vous proposons un accompagnement qui vise d'abord à établir vos priorités professionnelles."
        quote="Ce qui nous empêche de changer, ce ne sont pas seulement nos doutes, mais bien plus souvent nos certitudes… <span>- Sénèque</span>"
        background="coaching" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Tirez profit de vos talents, compétences et expériences afin de réaliser vos rêves dès maintenant!    </p>
        <div className="flex-container pad2">
            <div className="half"><ImageLeft4 /></div>
            <div className="half">
                <ul className="success">
                    <li>Identifier vos sources d’inconfort au travail</li>
                    <li>Revisiter vos aspirations et vos objectifs professionnels</li>
                    <li>Maximiser votre plein potentiel en ciblant ce qui vous anime</li>
                    <li>Préciser votre cheminement de carrière et vos prochaines étapes</li>
                    <li>Établir avec justesse votre stratégie de recherche d’emploi </li>
                    <li>Miser sur la vivacité de vos outils promotionnels (votre curriculum vitæ, portfolio, LinkedIn, etc.)</li>
                </ul>
            </div>
        </div>
        <p class="service-presentation2">Nous vous guidons afin de prendre le recul nécessaire pour y voir plus clair. Nous vous accompagnons de façon à faire des choix réfléchis. Nous vous aiderons à miser sur votre assurance renouvelée et ainsi, régénérer votre pouvoir d’agir!</p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
        <div className="upperline"></div>
        <br /><br />
        <h1>Services connexes</h1>
        <br /><br /><br /><br />
        <div className="flex-container2">
            <ScrollAnimation
                delay="20"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/developpement-des-competences">
                    <ImageDevelopement2 />
                    <h6>Développement de compétences relationnelles ou de gestion</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="30"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/integration-a-de-nouvelles-fonctions">
                    <ImageCoaching2 />
                    <h6>Intégration à de nouvelles fonctions de gestion</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="40"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/recherche-emploi">
                    <ImageEmploi />
                    <h6>Recherche d’emploi</h6>
                </Link>
            </div>
            </ScrollAnimation>
        </div>
    </div>
  </Layout>
)

export default Transition
